<template>
  <v-container fluid class="sw-mt-11">
    <div class="text-center">
      <h2>
        {{ $vuetify.lang.t("$vuetify.forbiddenHeadLine") }}
      </h2>
      <p>
        {{ $vuetify.lang.t("$vuetify.forbiddenText") }}
      </p>
      <a @click.stop="toHomePage">{{
        $vuetify.lang.t("$vuetify.goToHomePage")
      }}</a>
    </div>
  </v-container>
</template>

<script>
export default {
  methods: {
    toHomePage() {
      this.$router.push({ name: "FrontPage" }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
